import React from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"

import Button from "../components/Button"
import { Title, Paragraph } from "../components/Typography"

import { CAREER } from "../navigation/constants"

const HERO_IMG =
  "https://firebasestorage.googleapis.com/v0/b/abc-web-45f9b.appspot.com/o/assets%2Fmove.jpg?alt=media&token=0b9e7ece-db6d-4e75-83e7-542598bc46c7"
const HERO_MP4 =
  "https://firebasestorage.googleapis.com/v0/b/abc-web-45f9b.appspot.com/o/assets%2Fmove.mp4?alt=media&token=3ed61ac0-73ab-4b65-9b72-44c43f947901"
const HERO_WEBM =
  "https://firebasestorage.googleapis.com/v0/b/abc-web-45f9b.appspot.com/o/assets%2Fmove.webm?alt=media&token=23d0f222-52b0-40bd-9d86-d1f7b1120f5b"

const GRID_IMG_1 =
  "https://firebasestorage.googleapis.com/v0/b/abc-web-45f9b.appspot.com/o/assets%2Fitem1.svg?alt=media&token=637b0ac8-bd76-4136-8f04-c0fd6064ec6d"
const GRID_IMG_2 =
  "https://firebasestorage.googleapis.com/v0/b/abc-web-45f9b.appspot.com/o/assets%2Fitem2.svg?alt=media&token=e8059d6e-9468-4d9a-9968-a998ca8c11b2"
const GRID_IMG_3 =
  "https://firebasestorage.googleapis.com/v0/b/abc-web-45f9b.appspot.com/o/assets%2Fitem3.svg?alt=media&token=2372e28b-060c-4170-b0d2-e402af255fba"

function AboutUs() {
  return (
    <Wrapper>
      {/** MISSION */}
      <HeroSection>
        <VideoWrapper>
          <Video loop playsInline autoPlay muted poster={HERO_IMG}>
            <source src={HERO_WEBM} type="video/webm"></source>
            <source src={HERO_MP4} type="video/mp4"></source>
            <VideoPoster src={HERO_IMG} />
          </Video>
        </VideoWrapper>
        <Box>
          <HeroTitle>Powering People</HeroTitle>
        </Box>
      </HeroSection>
      {/** CENTERED GRID */}
      <Grid container>
        {/** WE BELEIVE */}
        <Grid item>
          <TextContent>
            <h2>
              We believe... It’s our mission to keep you motivated and ensure
              you can reach your goals. We do that by guiding you in small
              steps, show you your potential and ensure you can access the
              learning needed.
            </h2>
          </TextContent>
        </Grid>
        {/** ITEM 1 */}
        <GridWrapper>
          <Grid item alignItems="stretch">
            <GridItem top>
              <GridLink block="true" to={"#"}>
                <GridImage src={GRID_IMG_1} />
              </GridLink>
            </GridItem>
            <GridItem>
              <GridContent>
                <GridLink to={"#"}>
                  <Title mb={5} mb_md={8}>
                    Title
                  </Title>
                  <Paragraph mb={5} mb_md={8}>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it
                  </Paragraph>
                  {/* <div>
                    <Button title="Learn more" size="small" />
                  </div> */}
                </GridLink>
              </GridContent>
            </GridItem>
          </Grid>
        </GridWrapper>
        {/** ITEM 2 */}
        <GridWrapper>
          <Grid item reverse alignItems="stretch">
            <GridItem top>
              <GridLink block="true" to={"#"}>
                <GridImage src={GRID_IMG_2} />
              </GridLink>
            </GridItem>
            <GridItem>
              <GridContent>
                <GridLink to={"#"}>
                  <Title mb={5} mb_md={8}>
                    Title
                  </Title>
                  <Paragraph mb={5} mb_md={8}>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it
                  </Paragraph>
                  {/* <div>
                    <Button title="Learn more" size="small" />
                  </div> */}
                </GridLink>
              </GridContent>
            </GridItem>
          </Grid>
        </GridWrapper>
        {/** ITEM 3 */}
        <GridWrapper>
          <Grid item alignItems="stretch">
            <GridItem top>
              <GridLink block="true" to={"#"}>
                <GridImage src={GRID_IMG_3} />
              </GridLink>
            </GridItem>
            <GridItem>
              <GridContent>
                <GridLink to={"#"}>
                  <Title mb={5} mb_md={8}>
                    Title
                  </Title>
                  <Paragraph mb={5} mb_md={8}>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it
                  </Paragraph>
                  {/* <div>
                    <Button title="Learn more" size="small" />
                  </div> */}
                </GridLink>
              </GridContent>
            </GridItem>
          </Grid>
        </GridWrapper>
      </Grid>
      <FoundingWrapper>
        <FoundingInner>
          <FoundingGrid>
            <FoundingItem top>
              <img src={"https://placebeard.it/500x503?id=132315"} />
            </FoundingItem>
            <FoundingItem>
              <h2>Meet our founders</h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p>
            </FoundingItem>
          </FoundingGrid>
        </FoundingInner>
      </FoundingWrapper>
      <CareerWrapper>
        <div>
          <span>Join us and build the open Human Cloud Network.</span>
        </div>
        <div>
          <Button
            to={CAREER}
            as={Link}
            mt={8}
            title="See Careers"
            color="black"
          />
        </div>
      </CareerWrapper>
    </Wrapper>
  )
}

const HeroSectionCss = css`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  background: url(${HERO_IMG});
  background-repeat: no-repeat;
  background-size: cover;
`

const Wrapper = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
`

const HeroSection = styled.div`
  height: 335px;
  max-height: 75vh;
  ${({ theme }) => theme.breakpoint.up.sm} {
    height: 435px;
  }
  ${({ theme }) => theme.breakpoint.up.md} {
    height: 500px;
  }
  ${({ theme }) => theme.breakpoint.up.lg} {
    height: 540px;
  }
  ${({ theme }) => theme.breakpoint.up.xl} {
    min-height: 600px;
    max-height: 700px;
    height: 70vh;
  }
  ${HeroSectionCss}
`

const Box = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const VideoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
`

const VideoPoster = styled.img`
  max-width: 100%;
  height: 100%;
  width: 100%;
  object-fit: contain;
  user-select: none;
`

const gridContainerCss = css`
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  min-width: 320px;
  max-width: 100%;
  padding-left: ${({ theme }) => theme.spacing[6]};
  padding-right: ${({ theme }) => theme.spacing[6]};
  ${({ theme }) => theme.breakpoint.up.md} {
    min-width: ${({ theme }) => theme.screens.md};
    max-width: 100%;
    padding-left: ${({ theme }) => theme.spacing[8]};
    padding-right: ${({ theme }) => theme.spacing[8]};
  }
  ${({ theme }) => theme.breakpoint.up.lg} {
    min-width: ${({ theme }) => theme.screens.lg};
    max-width: 100%;
    padding-left: ${({ theme }) => theme.spacing[9]};
    padding-right: ${({ theme }) => theme.spacing[9]};
  }
  ${({ theme }) => theme.breakpoint.up.xl} {
    min-width: ${({ theme }) => theme.screens.xl};
    max-width: ${({ theme }) => theme.screens.xl};
  }
`

const gridItemCss = css`
  margin-left: -6px;
  margin-right: -6px;
  width: calc(100% + 12px);
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
  justify-content: center;
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "flex-start")};
  ${({ theme }) => theme.breakpoint.up.md} {
    margin-left: -7px;
    margin-right: -7px;
    width: calc(100% + 14px);
  }
  ${({ theme }) => theme.breakpoint.up.lg} {
    margin-left: -9px;
    margin-right: -9px;
    width: calc(100% + 18px);
  }
`

const Grid = styled.div`
  ${({ container }) => container && gridContainerCss};
  ${({ item }) => item && gridItemCss};
  ${({ mb, theme }) => mb && theme.spacing[mb]};
`

const GridWrapper = styled.div`
  width: 288px;
  margin: 0px auto 108px;
  ${({ theme }) => theme.breakpoint.up.md} {
    width: unset;
    margin: unset;
    margin-bottom: 115px;
  }
`

const GridItem = styled.div`
  margin-left: 6px;
  margin-right: 6px;
  width: calc(100% - 12px);
  display: block;
  visibility: visible;
  ${({ top }) => top && "margin-bottom: 34px"};
  ${({ theme }) => theme.breakpoint.up.md} {
    margin-bottom: unset;
    margin-left: 7px;
    margin-right: 7px;
    width: calc(50% - 14px);
    display: block;
    visibility: visible;
  }
  ${({ theme }) => theme.breakpoint.up.lg} {
    margin-left: 9px;
    margin-right: 9px;
    width: calc(33.333% - 18px);
    display: block;
    visibility: visible;
  }
  ${({ theme }) => theme.breakpoint.up.xl} {
    margin-left: 9px;
    margin-right: 9px;
    width: 390px;
    display: block;
    visibility: visible;
  }
`

const GridLink = styled(Link)`
  ${({ block }) => block && "display: flex"};
  ${({ block }) => block && "justify-content: center"};
  text-decoration: none;
  color: inherit;
`

const GridImage = styled.img`
  height: 500px;
  border-radius: 25px;
  width: 288px;
`

const GridContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`

const HeroTitle = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily.headings};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  font-size: 34px;
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  text-decoration: unset;
  color: #fff;
  margin: 0 ${({ theme }) => theme.spacing[3]};
  max-width: 430px;
  ${({ theme }) => theme.breakpoint.up.sm} {
    max-width: 530px;
    font-size: 42px;
  }
  ${({ theme }) => theme.breakpoint.up.md} {
    max-width: 530px;
    font-size: 46px;
  }
  ${({ theme }) => theme.breakpoint.up.lg} {
    max-width: 700px;
    font-size: 58px;
  }
  ${({ theme }) => theme.breakpoint.up.xl} {
    max-width: 900px;
    font-size: 62px;
  }
`

const TextContent = styled.div`
  margin: 112px 6px;
  width: calc(100% - 12px);
  display: block;
  visibility: visible;
  & > h2 {
    font-family: ${({ theme }) => theme.fontFamily.headings};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    font-size: 24px;
    letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
    text-decoration: unset;
    color: ${({ theme }) => theme.palette.text.primary};
  }
  ${({ theme }) => theme.breakpoint.up.md} {
    margin: 112px 7px;
    width: calc(100% - 14px);
    & > h2 {
      font-size: 32px;
    }
  }
  ${({ theme }) => theme.breakpoint.up.lg} {
    margin: 112px auto;
    width: calc(50%);
  }
  ${({ theme }) => theme.breakpoint.up.xl} {
    margin: 112px auto;
    width: 594px;
  }
`

const FoundingWrapper = styled.div`
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding: 120px 0px;
  box-sizing: border-box;
  background: rgb(225, 235, 220);
`

const FoundingInner = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  min-width: 320px;
  max-width: 100%;
  padding-left: ${({ theme }) => theme.spacing[6]};
  padding-right: ${({ theme }) => theme.spacing[6]};
  ${({ theme }) => theme.breakpoint.up.md} {
    min-width: ${({ theme }) => theme.screens.md};
    max-width: 100%;
    padding-left: ${({ theme }) => theme.spacing[8]};
    padding-right: ${({ theme }) => theme.spacing[8]};
  }
  ${({ theme }) => theme.breakpoint.up.lg} {
    min-width: ${({ theme }) => theme.screens.lg};
    max-width: 100%;
    padding-left: ${({ theme }) => theme.spacing[9]};
    padding-right: ${({ theme }) => theme.spacing[9]};
  }
  ${({ theme }) => theme.breakpoint.up.xl} {
    min-width: ${({ theme }) => theme.screens.xl};
    max-width: ${({ theme }) => theme.screens.xl};
  }
`

const FoundingGrid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  max-width: 815px;
  margin: 0 auto;
  ${({ theme }) => theme.breakpoint.up.md} {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
  }

  ${({ theme }) => theme.breakpoint.up.lg} {
  }
  ${({ theme }) => theme.breakpoint.up.xl} {
  }
`

const FoundingItem = styled.div`
  margin: ${({ theme }) => theme.spacing[4]} 0;
  flex-basis: ${({ top }) => (top ? 33 : 66)}%;
  & > h2 {
    font-family: ${({ theme }) => theme.fontFamily.headings};
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
    font-size: 24px;
    letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
    text-decoration: unset;
    color: ${({ theme }) => theme.palette.text.primary};
    margin-bottom: 16px;
  }
  & > img {
    width: 250px;
    height: auto;
    margin-top: 0;
    margin-bottom: ${({ theme }) => theme.spacing[8]};
    border-radius: 50%;
  }
  ${({ theme }) => theme.breakpoint.up.md} {
    margin: 0 ${({ theme }) => theme.spacing[4]};
    & > h2 {
      font-size: 32px;
    }
    & > img {
      width: 288px;
      height: auto;
      margin-top: 8px;
      margin-bottom: 0;
    }
  }
`

const CareerWrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 64px 24px;
  font-family: ${({ theme }) => theme.fontFamily.headings};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  font-size: 21px;
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  text-decoration: unset;
  color: ${({ theme }) => theme.palette.text.primary};
  text-align: center;
  ${({ theme }) => theme.breakpoint.up.md} {
    font-size: 24px;
  }
  ${({ theme }) => theme.breakpoint.up.lg} {
  }
  ${({ theme }) => theme.breakpoint.up.xl} {
  }
`

export default AboutUs
